import { useHistory } from "react-router-dom";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../Constant/swalMessage";
import { AccountsType } from "../Store/Account/Account.type";
import {
  changePasswordSerivce,
  checkAccountNameExistService,
  directLoginService,
  generateVerificationCodeService,
  getUerAccountsDetailsService,
  googleLoginSerivce,
  lastLoginTrackService,
  loginService,
  registerNewAccount,
  resetPasswordService,
  saveAccountNameService,
  saveFeedbackService,
  saveUserDetailsService,
  verifyEmailWithToken,
  verifyGhlWithToken,
  verifyVerificationCodeService,
} from "../services/commonServices";
import { axiosClient } from "../../../Constant/apiConstant";

export const verifyEmailAction = (data) => async () => {
  let res = await verifyEmailWithToken(data);
  if (res?.status === 200) {
    swalSuccessMessage("Successfully Verified, Please login to continue");
  } else {
    swalErrorMessage(res?.errMsg);
  }
};
export const verifyGhlAction = (data) => async () => {
  let res = await verifyGhlWithToken(data);
  if (res?.status === 200) {
    swalSuccessMessage("Successfully Verified");
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const registerNewAccountAction = (data) => async () => {
  let res = await registerNewAccount(data);
  if (res?.status === 200) {
    swalSuccessMessage(
      "Verification Email has been sent to your Email Id, Please verify to continue, if not received please check once on spam folder",
      5000
    );
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const loginAction = (data) => async () => {
  let res = await loginService(data);
  if (res?.status === 200) {
    localStorage.setItem("accountInfo", JSON.stringify(res));
    localStorage.setItem("token", res?.data.token);
    axiosClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res?.data.token}`;

    return res?.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const saveAccountNameAction = (data, history) => async () => {
  let res = await saveAccountNameService(data);
  if (res?.status === 200) {
    swalSuccessMessage("Account Created Successfully");
    history.push("/onboarding");
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const checkAccountNameExistAction = (data) => async () => {
  let res = await checkAccountNameExistService(data);
  if (res?.status === 200) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

const getLastLoginData = (payload) => {
  const { accounts, lastLogin } = payload;
  let find = accounts.find((acc) => acc._id === lastLogin.accountId);
  return find;
};
export const getUerAccountsDetailsAction = (data) => async (dispatch) => {
  let res = await getUerAccountsDetailsService(data);
  if (res?.status === 200) {
    let selectedAccount = getLastLoginData(res?.data || {});
    await dispatch({
      type: AccountsType.GET_ACCOUNT_DETAILS,
      payload: { data: res?.data, selectedAccount: selectedAccount },
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const saveUserDetailsAction = (data) => async (dispatch) => {
  let res = await saveUserDetailsService(data);
  if (res?.status === 200) {
    swalSuccessMessage(res.msg);
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const resetPasswordAction = (data) => async () => {
  let res = await resetPasswordService(data);
  if (res?.status === 200) {
    swalSuccessMessage(
      "Verification Email has been sent to your Email Id, Please verify to continue, if not received please check once on spam folder",
      5000
    );
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const changePasswordAction = (data) => async () => {
  let res = await changePasswordSerivce(data);
  if (res?.status === 200) {
    swalSuccessMessage("Password Changed Successfully", 5000);
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const googleLoginAction = (data) => async () => {
  let res = await googleLoginSerivce(data);
  if (res?.status === 200) {
    localStorage.setItem("accountInfo", JSON.stringify(res));
    localStorage.setItem("token", res?.data.token);
    axiosClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res?.data.token}`;

    return res?.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const directLogin = (data) => async () => {
  let res = await directLoginService(data);
  if (res?.status === 200) {
    localStorage.setItem("accountInfo", JSON.stringify(res));
    localStorage.setItem("token", res?.data.token);
    axiosClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res?.data.token}`;

    return res?.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const lastLoginTrackAction = (data) => async (dispatch) => {
  let res = await lastLoginTrackService(data);
  if (res?.status === 200) {
    await dispatch({
      type: AccountsType.ACCOUNT_ROLES,
      payload: res?.data,
    });
    return res?.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const saveFeedbackAction = (data) => async (dispatch) => {
  let res = await saveFeedbackService(data);
  if (res?.status === 200) {
    return res?.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const generateVerificationCodeAction = (data) => async () => {
  let res = await generateVerificationCodeService(data);
  if (res?.status === 200) {
    swalSuccessMessage("Verification Code Sent");
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const verifyVerificationCodeAction = (data, history) => async () => {
  let res = await verifyVerificationCodeService(data);
  if (res?.status === 200) {
    swalSuccessMessage("Successfully Verified");
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};
