import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { LoadingDots } from "./Common";

function ConfirmationPopup({
  heading,
  message,
  show,
  toggle,
  handleSave,
  spin = false,
  additionalHtml = <></>,
}) {
  const containerStyle: any = {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2d2d2d",
    border: "1px solid #2d2d2d",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
  };

  const buttonStyle = {
    padding: "0.5rem 1rem",
    fontSize: "0.875rem",
    fontWeight: "500",
    letterSpacing: "0.05em",
    borderRadius: "9999px",
    transition: "all 0.3s ease-in-out",
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={toggle}
        size={"sm"}
        backdrop="static"
        keyboard={false}
        contentClassName="w-20"
        centered={true}
      >
        <div style={containerStyle}>
          <div
            style={{
              textAlign: "center",

              flex: "1",
              justifyContent: "center",
            }}
          >
            <svg
              fill="#f56565"
              viewBox="0 0 20 20"
              style={{
                width: "3rem",
                height: "3rem",
                margin: "0 auto",

                animation: "bounce 1s infinite",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                fillRule="evenodd"
                style={{
                  animation: "bounce 1s infinite",
                  backgroundColor: "#f56565",
                }}
              ></path>
            </svg>
            <h2
              style={{
                fontSize: "1.25rem",
                fontWeight: "700",
                padding: "1rem",
                color: "#edf2f7",
                marginBottom: "0px",
              }}
            >
              Are you sure?
            </h2>
            <span
              style={{
                fontWeight: "600",
                fontSize: "0.875rem",
                marginTop: "-10px",
              }}
            >
              <p
                style={{
                  color: "#dcd6d6",
                }}
              >
                {message}
                <br />
              </p>{" "}
              <span
                style={{
                  color: "#dcd6d6",
                }}
              >
                {additionalHtml}
              </span>
            </span>
          </div>
          <div
            style={{
              padding: "0.5rem 1rem",
              marginTop: "0.5rem",
              textAlign: "center",
            }}
          >
            <button
              onClick={toggle}
              style={{
                ...buttonStyle,
                backgroundColor: "#4a5568",
                borderColor: "#2d3748",
                color: "#cbd5e0",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              }}
            >
              Cancel
            </button>
            <button
              style={{
                ...buttonStyle,
                backgroundColor: "#f56565",
                borderColor: "transparent",
                color: "#fff",
                marginLeft: "0.25rem",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              }}
              disabled={spin}
              onClick={handleSave}
              type="submit"
            >
              {spin ? <LoadingDots /> : "Confirm"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmationPopup;
