import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEventHandler,
} from "react";
import { Carousel, CarouselItem } from "reactstrap";
import {
  LoadingDots,
  LoadingDotsSpin,
  isVodexLoggedin,
  renderThumb,
} from "../CommonComponents/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  swalConfirmationMessage,
  swalWarningMessage,
} from "../../../Constant/swalMessage";
import VodexImageOrange from "../../../assets/Images/VodexOrange.svg";
import VodexImageWhite from "../../../assets/Images/VodexLogoWhite.svg";
import UserImage from "../../../assets/Images/user.svg";
import OnboardingPageImage1 from "../../../assets/Images/OnboardingPageImage1.svg";
import CallPage from "../../../assets/Images/RecievedCallPage.svg";
import NoResponse from "../../../assets/Images/NoResponse.svg";
import SupportImg from "../../../assets/Images/SupportImg.svg";
import "./Login.css";
import { Scrollbars } from "react-custom-scrollbars";
import {
  callAgentAction,
  handleOnbiardingChatAgentAction,
  saveAgentAction,
} from "../ProjectSection/Agents/Store/Agent.action";
import { saveProjectsAction } from "../Projects/Store/projects.action";
import {
  getUerAccountsDetailsAction,
  saveFeedbackAction,
} from "../Actions/CommonActions";
import PhoneInput from "react-phone-input-2";
import "../ProjectSection/CallSettings/CallSetting.css";
import ModalWithoutBody from "./ModalWithoutBody";
import { sendSmsToSupportAction } from "../AccessManger/Store/access.action";
import { useHistory, useLocation } from "react-router-dom";
import { axiosClient } from "../../../Constant/apiConstant";
import ReactMarkdown from "react-markdown";

const Message = ({ message, isUser, index, big = false }) => {
  return (
    <>
      <div
        style={{
          textAlign: isUser ? "right" : "left",
          margin: "8px 0",
        }}
      >
        <div
          style={{
            display: "inline-block",
          }}
        >
          <div className="d-flex">
            {!isUser &&
              (!isVodexLoggedin() ? (
                <img className="rounded-circle mr-2" src={VodexImageOrange} />
              ) : (
                <i
                  className="fas fa-robot rounded-circle mr-2 "
                  style={{ color: "#3A416F", fontSize: "25px" }}
                ></i>
              ))}
            <section
              style={{
                padding: "8px",
                borderRadius: "8px",
                border: !isUser ? "1px solid #ff5e00" : "none",
                background: isUser ? "#FFDBCC" : "",
              }}
            >
              <p
                style={{
                  margin: "0",
                  maxWidth: big ? "60vw !important" : "436px",
                  textAlign: "left",
                }}
              >
                <span>
                  {" "}
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </span>
              </p>
              <small style={{ color: "black" }}>{message.timestamp}</small>
            </section>
            {isUser && <img className="rounded-circle ml-2" src={UserImage} />}
          </div>
        </div>{" "}
        <br />
      </div>
    </>
  );
};
function CustomNextButton({ onClick, name = "next", ref, from = "" }) {
  return (
    <button
      ref={ref}
      className={`${from === "dashboard" ? "custom-save-button-1" : "custom-save-button"
        }`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      style={{ backgroundColor: isVodexLoggedin() ? "" : "#3A416F" }}
    >
      {name}
    </button>
  );
}

function CustomNextButton1({
  onClick,
  name = "next",
  color = "",
  htm = <></>,
  html = false,
  spinButton = false,
  className = "",
}) {
  return (
    <button
      style={{ backgroundColor: color }}
      className={`custom-save-button-1 ${isVodexLoggedin() ? "" : "white-label-colour"
        } ${className}`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {spinButton ? <LoadingDots /> : html ? htm : name}
    </button>
  );
}

const items = [
  {
    id: 0,
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    id: 1,
    altText: "Slide 2",
    caption: "Slide 2",
  },
  {
    id: 2,
    altText: "Slide 3",
    caption: "Slide 3",
  },
  {
    id: 3,
    altText: "Slide 3",
    caption: "Slide 3",
  },
];

function OnboardingPage() {
  const [projectId, setProjectId] = useState("");
  const messagesContainerRef: any = useRef(null);
  const messagesContainerRef1: any = useRef(null);
  const [messages, setMessages] = useState<any>([]);
  const [history, setHistory] = useState<any>([]);
  const [inputText, setInputText] = useState("");
  const historyPush = useHistory();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let from = locSearch.get("from");
  useEffect(() => {
    if (messagesContainerRef.current) {
      const { scrollHeight, clientHeight } =
        messagesContainerRef.current.getValues();
      const maxScrollTop = scrollHeight - clientHeight;
      messagesContainerRef.current.scrollTop(maxScrollTop);
    }
    if (messagesContainerRef1.current) {
      const { scrollHeight, clientHeight } =
        messagesContainerRef1.current.getValues();
      const maxScrollTop = scrollHeight - clientHeight;
      messagesContainerRef1.current.scrollTop(maxScrollTop);
    }
  }, [messages]);

  const reducer: any = useSelector((state) => state);
  const {
    AccountReducer: { userDetails, selectedAccount },
  } = reducer;
  useEffect(() => {
    dispatch(getUerAccountsDetailsAction());
  }, []);
  useEffect(() => {
    axiosClient.defaults.headers.common[
      "dbUrl"
    ] = `${selectedAccount?.accountUrl}`;
  }, [selectedAccount]);
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLTextAreaElement>(null);

  interface CurrentProjectData {
    currentProjectId: String;
    currentProjectName: String;
  }
  const currentDataRef = useRef<CurrentProjectData | null>(null);

  const [state, setState] = useState<any>({
    seletedType: "",
    conversationData: [{ input: "Hi", output: "" }],
    agentName: "",
    organisationName: "",
    greetingMessage: "",
    context: "",
    rules: "",
    objections: [],
    voicemailMessage: "",
    projectName: "",
    firstName: "",
    phone: "",
    agentId: "",
    call_uuid: "",
    feedbackText: "",
    starsFeedback: 0,
    feedBackSaved: "",
    chatId: "",
  });

  const {
    agentName,
    organisationName,
    greetingMessage,
    context,
    rules,
    projectName,
    phone,
    firstName,
    agentId,
    call_uuid,
    feedbackText,
    starsFeedback,
    feedBackSaved,
    chatId,
  } = state;
  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [spin, setSpin] = useState(false);
  const [firstShow, setFirstShow] = useState(false);
  const [feedbackShow, setFeedbackShow] = useState(false);
  const [noResponseShow, setNoResponseShow] = useState(false);
  const [supportShow, setSupportShow] = useState(false);
  const [textSpin, setTextSpin] = useState(false);
  const [spinButton, setSpinButton] = useState(false);
  const imageShow = () =>
    isVodexLoggedin() && <img className="vodex-logo" src={VodexImageOrange} />;
  const imageShowWhite = () =>
    isVodexLoggedin() && (
      <>
        <img className="vodex-logo ml-4 mobile-view" src={VodexImageWhite} />
        <img className="vodex-logo ml-4 web-view" src={VodexImageOrange} />
      </>
    );

  useEffect(() => {
    const currentProjectData = localStorage.getItem("currentSlideData");

    if (currentProjectData) {
      currentDataRef.current = JSON.parse(currentProjectData);
    } else {
      currentDataRef.current = null;
    }

    if (currentDataRef.current && currentDataRef.current.currentProjectId) {
      setActiveIndex(1);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (activeIndex === 0) {
        document.getElementById("projectName")?.focus();
      }
      if (activeIndex === 1) {
        document.getElementById("agentName")?.focus();
      }
      if (activeIndex === 2) {
        document.getElementById("chat-focus")?.focus();
      }
      if (activeIndex === 3) {
        document.getElementById("firstName")?.focus();
      }
    }, 1000);
  }, [activeIndex]);

  const checkValidation = () => {
    if (activeIndex === 0) {
      if (currentDataRef.current?.currentProjectId) {
        return true;
      }
      if (projectName.trim()) {
        if (!projectId) {
          handleSave();
        }
        return true;
      } else {
        swalWarningMessage("Project Name is required");
        return;
      }
    }
    if (activeIndex === 1) {
      if (
        agentName.trim() &&
        organisationName.trim() &&
        greetingMessage.trim()
      ) {
        if (history?.length === 0) {
          handleTestBot();
        }
        return true;
      } else {
        swalWarningMessage("All fields are required");
        return;
      }
    }
    if (activeIndex === 2) {
      if (context.trim()) {
        if (!agentId) {
          save();
        }
        return true;
      } else {
        swalWarningMessage("Please complete the chat first");
        return;
      }
    }

    return true;
  };

  const callTrigger = async () => {
    if (firstName.trim() && phone.trim() && phone?.length > 8) {
      setSpin(true);
      let obj = {
        callList: [{ phone: phone, firstName: firstName, lastName: "" }],
        projectId: projectId || currentDataRef.current?.currentProjectId,
        agentId: agentId,
        campaignName: `Test ${agentId}`,
        consentForCalls: true,
      };
      let res: any = await dispatch(callAgentAction(obj, false));

      if (res?.data?.data?.apiRes?.successful?.length > 0) {
        setState((prev) => ({
          ...prev,
          call_uuid: res?.data?.data?.apiRes?.successful[0]?.call_uuid,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          call_uuid: res?.data?.data?.apiRes?.failed[0]?.call_uuid,
        }));
      }
      setShow(true);
      setFirstShow(true);
      setSpin(false);
    } else {
      swalWarningMessage("Please fill all the fields");
    }
  };

  const handleTestBot = async () => {
    setTextSpin(true);
    let sessionId = new Date(Date.now()).getTime().toString();
    let obj1 = {
      agent_name: agentName,
      organization_name: organisationName,
      greeting_message: greetingMessage,
      message: "Hi",
      messages: history,
      session_id: sessionId,
      new: from === "dashboard" ? false : true,
    };

    let res1: any = await dispatch(handleOnbiardingChatAgentAction(obj1));
    const botResponse = {
      id: messages.length + 2,
      text: res1.text,
      timestamp: new Date().toLocaleTimeString(),
      isUser: false,
    };
    setMessages([...messages, botResponse]);

    setHistory([
      ...history,
      {
        content: "Hi",
        author: "user",
      },
      {
        content: res1.text,
        author: "bot",
      },
    ]);
    setState((prev) => ({
      ...prev,
      chatId: sessionId,
    }));
    setTextSpin(false);
    document.getElementById("chat-focus")?.focus();
  };

  const handleSendMessage = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let inputText1 = inputText;
    if (inputText.trim() === "") {
      return;
    }
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = 0 + "px";
    }
    setTextSpin(true);
    const userMessage = {
      id: messages.length + 1,
      text: inputText1,
      timestamp: new Date().toLocaleTimeString(),
      isUser: true,
    };

    let obj = {
      agent_name: agentName,
      organization_name: organisationName,
      greeting_message: greetingMessage,
      message: inputText1,
      messages: history,
      session_id: chatId,
      new: from === "dashboard" ? false : true,
    };

    let obj2 = {
      agent_name: "Sarah",
      organization_name: "Vodex",
      greeting_message: "Hello {FirstName}. Glad to meet you today",
      messages: [
        {
          content: "Hi",
          author: "user",
        },
        {
          content:
            "Hi there! Im Mouni, and Im here to help you craft the perfect prompt for your AI sales agent. Lets start by gathering some information about your business and goals. First, could you tell me a little about the industry your business operates in? For example, are you in technology, healthcare, finance, or something else?",
          author: "assistant",
        },
        {
          content: "Technology",
          author: "user",
        },
        {
          content:
            "Great! Technology is a broad field, so to get a better understanding, could you specify the area within technology that your business focuses on? For example, is it software development, cybersecurity, cloud computing, or something else?",
          author: "assistant",
        },
        {
          content: "Website development for small businesses",
          author: "user",
        },
        {
          content:
            "Fantastic! Now that we have a clearer picture of your industry and focus, lets delve into the specifics of the service youre offering. Can you describe the main service that your AI sales agent will be promoting during calls? For instance, is it a website builder tool, custom website development services, or something else related to website development for small businesses?",
          author: "assistant",
        },
        {
          content:
            "No, we build websites and maintain on behalf of clients. We develop using coding not by using any tool",
          author: "user",
        },
        {
          content:
            "Thats helpful! So, your AI agent will be promoting your website development and maintenance services to small businesses. Now, lets consider the typical use cases for your services. What are the main reasons small businesses would seek your website development and maintenance services? For example, are they looking to establish an online presence, improve their existing website, or boost their online sales?",
          author: "assistant",
        },
        {
          content: "All",
          author: "user",
        },
        {
          content:
            "Okay, so your services cater to a wide range of needs for small businesses looking to establish, improve, or optimize their online presence. Now, to add a bit of realism to your AI agents interactions, lets define a typical customer profile. Could you describe the characteristics of your ideal customer, such as their business size, industry, or specific challenges they face with their website?",
          author: "assistant",
        },
        {
          content:
            "Small business who dont have any website or have a very bad website or have a bad SEO",
          author: "user",
        },
        {
          content:
            "Excellent! Were building a comprehensive picture of your target audience. Now, lets focus on the specific value proposition you offer to these small businesses. What are the key benefits or advantages that your website development and maintenance services provide compared to your competitors or DIY solutions?",
          author: "assistant",
        },
        {
          content:
            "We actually help them to get more sales to them by improving interactivity etc",
          author: "user",
        },
        {
          content:
            "Thats a compelling value proposition! So, you not only build and maintain websites but also focus on improving interactivity and ultimately driving sales for your clients. This is valuable information that we can incorporate into the AI agents script. Now, lets move on to the objections that potential customers might raise during the call. What are some common concerns or hesitations that small businesses might have about using your services? For example, they might be worried about the cost, the time it takes to build a website, or the complexity of the process.",
          author: "assistant",
        },
        {
          content: "Can you help me here?",
          author: "user",
        },
        {
          content:
            "Absolutely! Here are a few common objections you might encounter from small businesses:\nCost: Im not sure I can afford a professional website.\nTime: How long will it take to build my website?\nComplexity: Im not tech-savvy, and the process seems overwhelming.\nControl: I want to be able to update my website myself.\nResults: How can I be sure that a new website will actually increase my sales?\nDo any of these resonate with your experience, or are there any other specific objections you frequently encounter?",
          author: "assistant",
        },
        {
          content:
            "So, cost is depends on the plans which we tell if they agree to speak with our sales team. Time is typically 1 month. Complexity is just they need to provide us with the logs and information. They have complete control on the information. And 100% sales is guranteed",
          author: "user",
        },
        {
          content:
            "Great! Now we have a good understanding of the potential objections and your corresponding responses. Lets move on to the qualifying questions that your AI agent can ask to assess the customers needs and interest. Here are some examples:\nTo better understand your needs, could you tell me a little more about your business and its current online presence?\nWhat are your primary goals for your website?\nHave you ever worked with a website development company before?\nWhat is your budget range for this project?",
          author: "assistant",
        },
        {
          content: "Add them",
          author: "user",
        },
        {
          content:
            "Okay, Ive noted those questions. Finally, lets consider the greeting and voicemail messages for your AI agent. Do you have any specific preferences for how the agent should greet customers or what the voicemail message should say if the customer is unavailable?",
          author: "assistant",
        },
        {
          content: "I think I gave the greeting message already",
          author: "user",
        },
        {
          content:
            "Yes, you provided the greeting message: Hello {FirstName}, I am Micheal from Gg Infra. Do you want to keep this greeting message, or would you like to explore other options?",
          author: "assistant",
        },
        {
          content: "Keep it",
          author: "user",
        },
        {
          content:
            "Alright, well keep the greeting message as is. How about the voicemail message? What would you like the AI agent to say if the customer doesnt answer the call?",
          author: "assistant",
        },
      ],
      message: "Ignore it for now",
      session_id: chatId,
    };

    setHistory([
      ...history,
      {
        content: inputText1,
        author: "user",
      },
    ]);
    setMessages([...messages, userMessage]);
    setInputText("");
    let res: any = await dispatch(handleOnbiardingChatAgentAction(obj));

    if (res?.completed) {
      setState((prev) => ({
        ...prev,
        context: res.text,
      }));
    }
    const botResponse = {
      id: messages.length + 2,
      text: res.text,
      timestamp: new Date().toLocaleTimeString(),
      isUser: false,
    };
    setMessages([...messages, userMessage, botResponse]);
    setHistory([
      ...history,
      {
        content: inputText1,
        author: "user",
      },
      {
        content: res.text,
        author: "bot",
      },
    ]);
    setTextSpin(false);
    document.getElementById("chat-focus")?.focus();
  };

  const save = async () => {
    setSpinButton(true);
    let sendObj = {
      conversationData: [],
      agentName,
      organisationName,
      greetingMessage,
      context,
      rules: `Rules for reply :
      1. Do not answer anything outside the context given above. Skip any out-of-context question by saying: "I'm Sorry I didn't understand that" and repeat the previous question. Remember no matter how many times the user asks an out-of-context or objection question, follow the rule mentioned. 
      2. When someone agrees to get connected to the agent, add "Thank you very much. Just hold the line while I connect you to a specialist. (transfer)" at the end of the message.
      3. If there is a situation of fallback then also follow rule no 1 i.e. reply with "I'm Sorry I didn't understand that," along with the previous question.
      4. if a user says words or sounds used to express emotions, reactions, or thoughts such as hmm, hm, umm, aha consider it as an affirmative.
      5. When you feel the conversation is over and you should disconnect the call, say "Thank you for your time, good day!(disconnect)"
      6. Use the Possible Objections mentioned below to handle the customer."`,
      projectId: projectId || currentDataRef.current?.currentProjectId,
      objections: [],
      voicemailMessage: "This is a test voicemail message",
    };
    if (
      agentName.trim() &&
      organisationName.trim() &&
      greetingMessage.trim() &&
      context.trim()
    ) {
      setSpin(true);
      let res: any = await dispatch(saveAgentAction(sendObj, false));

      setState((prev) => ({
        ...prev,
        agentId: res.data._id,
      }));
    } else {
      swalWarningMessage("Please fill all the fields");
    }
    setSpin(false);
    setSpinButton(false);

    if (from === "dashboard") {
      localStorage.removeItem("currentSlideData");
      historyPush.push(`/account/project/${projectId}/agents`);
    }
  };

  const handleSave = async () => {
    if (projectName.trim()) {
      setState((prev) => ({
        ...prev,
        spin: true,
      }));

      const obj = {
        projectName: projectName,
        projectAccess: [userDetails?._id],
        description: "my first project",
        botPlatform: "llm-tts",
        languageCode: "en",
      };

      let res: any = await dispatch(saveProjectsAction(obj, false));
      if (!res) {
        setActiveIndex(0);
        return;
      }
      console.log(res.data);
      setProjectId(res.data.projectId);

      if (res.data.projectId && projectName) {
        const currentSlideObj = JSON.stringify({
          currentProjectId: res.data.projectId,
          currentProjectName: projectName,
        });

        localStorage.setItem("currentSlideData", currentSlideObj);
      }
    } else {
      swalWarningMessage("Please enter required fields");
    }
  };

  const next = () => {
    if (!checkValidation()) {
      return;
    }

    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const onChange = (e) => {
    e.persist();

    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const signInButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      next();
    }
  };

  const handleKeyPress1 = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      callTrigger();
    }
  };

  const handleKeyPressForMessage: KeyboardEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage(false);
    }
  };

  const crossButton = () => (
    <span
      className="fas fa-times-circle float-right mt-2"
      style={{
        display: "flex",
        justifyContent: "end",
        marginRight: "10px",
        cursor: "pointer",
        fontSize: "18px",
      }}
      onClick={() => {
        localStorage.removeItem("currentSlideData");
        swalConfirmationMessage(
          "Are you sure you want to close this tab? All your progress will be lost.",
          "Continue to Dashboard",
          () => { },
          () => historyPush.push("/account/dashboard")
        );
      }}
    ></span>
  );

  const projectNameSlide = () => {
    return (
      <div className="project-container">
        <div className="card project-card">
          {crossButton()}
          {imageShow()}
          <div className="card-body">
            <div>
              <div
                className="centered-div project-image"
                style={{
                  marginLeft: "-5vw",
                  marginTop: "-43px",
                  paddingBottom: "58px",
                }}
              >
                <img src={OnboardingPageImage1} />
              </div>
              <br />
            </div>
          </div>

          <span className="text-align-center " style={{ fontSize: "1.4rem" }}>
            Welcome to the future of calling! Let's customize your experience!{" "}
          </span>

          <label
            className="mt-3"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "0px !important",
              paddingBottom: "0px !important",
              color: "#EF4444",
            }}
          >
            Name your {from === "dashboard" ? "" : "first"} project
          </label>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "0px !important",
              paddingBottom: "0px !important",
            }}
          >
            <input
              id="projectName"
              className="form-control project-input"
              placeholder="Sales"
              name="projectName"
              onChange={onChange}
              maxLength={25}
              disabled={
                projectId || currentDataRef.current?.currentProjectId
                  ? true
                  : false
              }
              value={projectName || currentDataRef.current?.currentProjectName}
              onKeyDown={handleKeyPress}
            />
          </span>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "0px !important",
              paddingBottom: "0px !important",
            }}
          >
            {" "}
            <div>
              {from === "dashboard" && (
                <CustomNextButton1
                  onClick={continueToDashboard}
                  name="Continue to Dashboard"
                  className=""
                />
              )}
              {activeIndex !== 3 && (
                <CustomNextButton
                  ref={signInButtonRef}
                  onClick={next}
                  name="Get Started"
                  from={`${from}`}
                />
              )}
            </div>
          </span>
        </div>
      </div>
    );
  };
  const agentSlide = () => {
    return (
      <div
        className={`card ${isVodexLoggedin() ? "agent-left" : "agent-left-whitelabel"
          } project-card`}
      >
        {crossButton()}
        {imageShowWhite()}
        <div className="card-body">
          <div>
            <div
              className="centered-div"
              style={{
                marginLeft: "-5vw",
                marginTop: "-40px",
                paddingBottom: "58px",
              }}
            >
              <div
                className={`${isVodexLoggedin() ? "steps" : "steps-whitelabel"
                  }`}
              >
                <span
                  className={`${isVodexLoggedin() ? "selected-span" : "selected-spanW"
                    }`}
                ></span>
                <span></span>
                <span></span>
              </div>
              <h5 className="mb-0 pb-0">Create your AI Agent</h5>
              <span>
                Let’s build your AI agent together & create something amazing
              </span>
              <form className="mt-4" onChange={onChange}>
                <label>
                  Name your bot*{" "}
                  <span>(AI agent name for introduction to customers) </span>
                </label>
                <input
                  id="agentName"
                  className="form-control"
                  name="agentName"
                  disabled={agentId ? true : false}
                  value={agentName}
                  onKeyDown={handleKeyPress}
                />
                <label>
                  Your organization name*{" "}
                  <span>(Company name for AI agent)</span>
                </label>

                <input
                  className="form-control"
                  name="organisationName"
                  disabled={agentId ? true : false}
                  value={organisationName}
                  onKeyDown={handleKeyPress}
                />
                <label>
                  Greeting message*{" "}
                  <span> (How should your AI agent greet customers) </span>
                </label>
                <input
                  className="form-control"
                  name="greetingMessage"
                  disabled={agentId ? true : false}
                  value={greetingMessage}
                  onKeyDown={handleKeyPress}
                />
              </form>
            </div>
            <br />
          </div>
        </div>

        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0px !important",
            paddingBottom: "0px !important",
          }}
        >
          {" "}
          <div>
            {activeIndex !== 3 && (
              <>
                {activeIndex !== 0 && (
                  <CustomNextButton1 onClick={previous} name="Previous" />
                )}
                <CustomNextButton1 onClick={next} name="Save & Next" />
              </>
            )}
          </div>
        </span>
      </div>
    );
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  };
  const openBigTextArea = (closeNav, id) => {
    return (
      <div
        id={`myNav${id}`}
        className="overlay1"
        style={{ overflow: "hidden", marginTop: "-34px", height: "108%" }}
      >
        <div
          className="overlay-content1"
          style={{
            background: "white",
            width: "98%",
            height: "86%",
            padding: "20px",
            border: "1px solid #ff5e00",
            borderRadius: "5px",
          }}
        >
          <i
            className="fas fa-close rounded-circle cursor-pointer"
            onClick={closeNav}
            style={{
              zIndex: 1,
              marginTop: "-17px",
              fontSize: "14px",
              backgroundColor: "whitesmoke",
              float: "right",
              padding: "10px",
            }}
          ></i>
          <Scrollbars
            ref={messagesContainerRef1}
            style={{
              height: "70vh",
              color: "white",
              borderRadius: "10px",
              marginTop: "2px",
            }}
            renderThumbVertical={renderThumb}
            autoHide={true}
          >
            <div
              style={{
                padding: "10px",
              }}
            >
              {messages.map((message, ind) => (
                <Message
                  key={message.id}
                  message={message}
                  isUser={message.isUser}
                  index={ind}
                  big={true}
                />
              ))}
            </div>
          </Scrollbars>
          {textSpin && <LoadingDotsSpin />}
          <div>
            {!context && (
              <form
                onSubmit={handleSendMessage}
                className="d-flex mt-3justify-content-center"
              >
                <section
                  className="chat-input-section-max"
                  style={{
                    border: isVodexLoggedin() ? "" : "1px solid #3A416F",
                  }}
                >
                  <textarea
                    className="input-max"
                    value={inputText}
                    onChange={(e) => {
                      setInputText(e.target.value);
                      adjustTextareaHeight();
                    }}
                    placeholder="Message Dex"
                    ref={inputRef}
                    onKeyDown={handleKeyPressForMessage}
                    disabled={textSpin}
                  />
                  <button
                    title="Send message"
                    type="submit"
                    className="send-button-max float-right"
                  >
                    <svg
                      width="32"
                      height="31"
                      viewBox="0 0 32 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.898438"
                        y="0.317383"
                        width="30.16"
                        height="30.16"
                        rx="11.6"
                        fill="#FF7133"
                      />
                      <rect
                        width="18.096"
                        height="18.096"
                        transform="translate(6.92969 6.35303)"
                        fill="#FF7133"
                      />
                      <path
                        d="M8.44699 22.1872L24.2735 15.4012L8.44699 8.61523L8.43945 13.8932L19.7495 15.4012L8.43945 16.9092L8.44699 22.1872Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </section>
              </form>
            )}
            {/* <p>Please send one message at a time</p> */}
          </div>{" "}
        </div>
      </div>
    );
  };
  function closeNav(id) {
    const myNav = document.getElementById(`myNav${id}`) as HTMLElement;
    if (myNav) {
      myNav.style.width = "0%";
    }
  }

  function openNav(id) {
    const myNav = document.getElementById(`myNav${id}`) as HTMLElement;
    if (myNav) {
      myNav.style.width = "100%";
    }
  }

  const chatSlide = () => {
    return (
      <div
        className={`card ${isVodexLoggedin() ? "agent-left" : "agent-left-whitelabel"
          } project-card `}
      >
        {crossButton()}{" "}
        {openBigTextArea(
          () => closeNav(1),

          1
        )}{" "}
        {imageShowWhite()}
        <div className="card-body">
          <div>
            <div
              className="centered-div ai-chat"
              style={{
                marginLeft: "-3vw",
                marginTop: "-20px",
                paddingBottom: "58px",
              }}
            >
              <div
                className={`${isVodexLoggedin() ? "steps" : "steps-whitelabel"
                  }`}
              >
                <span
                  className={`${isVodexLoggedin() ? "selected-span" : "selected-spanW"
                    }`}
                ></span>
                <span
                  className={`${isVodexLoggedin() ? "selected-span" : "selected-spanW"
                    }`}
                ></span>
                <span></span>
              </div>
              <h5 className="mb-0 pb-0">
                Train your AI Agent{" "}
                <span className="icon1" onClick={() => openNav(1)}>
                  <span className="fas fa-expand"></span>{" "}
                </span>
              </h5>
              <span>
                Your AI's got the brains, but it needs your guidance. Train your
                agent to sell like a pro
              </span>

              <Scrollbars
                ref={messagesContainerRef}
                style={{
                  height: "40vh",
                  color: "white",
                  borderRadius: "10px",
                  // border: "1px solid #d17842",
                  marginLeft: "-22px",
                  marginTop: "10px",
                }}
                renderThumbVertical={renderThumb}
                autoHide={true}
              >
                <div
                  style={{
                    padding: "10px",
                  }}
                >
                  {messages.map((message, ind) => (
                    <Message
                      key={message.id}
                      message={message}
                      isUser={message.isUser}
                      index={ind}
                    />
                  ))}
                </div>
              </Scrollbars>

              {textSpin && <LoadingDotsSpin />}
              <div>
                {!context && (
                  <form
                    onSubmit={handleSendMessage}
                    className="d-flex mt-3 justify-content-center"
                  >
                    <section
                      className="chat-input-section prompt-section"
                      style={{
                        border: isVodexLoggedin() ? "" : "1px solid #3A416F",
                      }}
                    >
                      <textarea
                        id="chat-focus"
                        className="input-mini prompt-area"
                        value={inputText}
                        onChange={(e) => {
                          setInputText(e.target.value);
                          adjustTextareaHeight();
                        }}
                        placeholder="Message Dex"
                        ref={inputRef}
                        onKeyDown={handleKeyPressForMessage}
                        disabled={textSpin}
                      />

                      <button
                        title="Send message"
                        type="submit"
                        className="send-button float-right"
                        style={{
                          alignSelf: "flex-end",
                        }}
                      >
                        <svg
                          width="32"
                          height="31"
                          viewBox="0 0 32 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.898438"
                            y="0.317383"
                            width="30.16"
                            height="30.16"
                            rx="11.6"
                            fill="#FF7133"
                          />
                          <rect
                            width="18.096"
                            height="18.096"
                            transform="translate(6.92969 6.35303)"
                            fill="#FF7133"
                          />
                          <path
                            d="M8.44699 22.1872L24.2735 15.4012L8.44699 8.61523L8.43945 13.8932L19.7495 15.4012L8.43945 16.9092L8.44699 22.1872Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </section>
                  </form>
                )}
                {/* <p>Please send one message at a time</p> */}
              </div>
            </div>
            <br />
          </div>
        </div>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0px !important",
            paddingBottom: "0px !important",
          }}
        >
          {" "}
          <div>
            {activeIndex !== 3 && (
              <>
                {activeIndex !== 0 && (
                  <CustomNextButton1 onClick={previous} name="Previous" />
                )}

                {from === "dashboard" ? (
                  <CustomNextButton1
                    onClick={async () => {
                      if (context.trim()) {
                        if (!agentId) {
                          await save();
                          historyPush.push(
                            `/account/project/${projectId}/agents`
                          );
                        }
                      } else {
                        swalWarningMessage("Please complete the chat first");
                        return;
                      }
                    }}
                    name="Continue to Project"
                    spinButton={spinButton}
                  />
                ) : (
                  <CustomNextButton1 onClick={next} name="Save & Next" />
                )}
              </>
            )}
          </div>
        </span>
      </div>
    );
  };

  const callSlide = () => {
    return (
      <div
        className={`card ${isVodexLoggedin() ? "agent-left" : "agent-left-whitelabel"
          } project-card`}
      >
        {crossButton()}
        {imageShowWhite()}
        <div className="card-body">
          <div>
            <div
              className="centered-div"
              style={{
                marginLeft: "-3vw",
                marginTop: "-20px",
                paddingBottom: "58px",
              }}
            >
              <div className="steps">
                <span
                  className={`${isVodexLoggedin() ? "selected-span" : "selected-spanW"
                    }`}
                ></span>
                <span
                  className={`${isVodexLoggedin() ? "selected-span" : "selected-spanW"
                    }`}
                ></span>
                <span
                  className={`${isVodexLoggedin() ? "selected-span" : "selected-spanW"
                    }`}
                ></span>
              </div>
              <h5 className="mb-0 pb-0">Launch a trial call</h5>
              <span>
                First test call with your new AI tool- who’s the lucky one ?
              </span>

              <form className="mt-4" onChange={onChange}>
                <label>Who should the bot call*</label>
                <input
                  className="form-control"
                  name="firstName"
                  value={firstName}
                  onKeyDown={handleKeyPress1}
                  id="firstName"
                />
                <label>What number should the bot call*</label>

                <PhoneInput
                  specialLabel={""}
                  country={"us"}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      phone: e,
                    }));
                  }}
                  value={phone}
                  enableLongNumbers={true}
                  onKeyDown={handleKeyPress1}
                />
              </form>
            </div>
            <br />
          </div>
        </div>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0px !important",
            paddingBottom: "0px !important",
          }}
        >
          {" "}
          <div>
            <CustomNextButton1 onClick={previous} name="Previous" />
            <CustomNextButton1
              color="#65A30D
"
              onClick={callTrigger}
              htm={spin ? <LoadingDots /> : <>Make a Sample Call</>}
              html={true}
            />
          </div>
        </span>
      </div>
    );
  };
  const continueToDashboard = () => {
    localStorage.removeItem("currentSlideData");
    historyPush.push("/account/dashboard");
  };
  const slides = items?.map((item) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div className="ml-10">
          {/* {item.id === 0 && agentSlide()} */}
          {item.id === 0 && projectNameSlide()}
          {/* {item.id === 0 && chatSlide()} */}
          {/* {item.id === 0 && callSlide()} */}
          {item.id === 1 && agentSlide()}
          {item.id === 2 && chatSlide()}
          {item.id === 3 && callSlide()}
        </div>
      </CarouselItem>
    );
  });

  const openFeedBack = () => {
    setFeedbackShow(true);
    setFirstShow(false);
  };

  const openNoResponseScreen = () => {
    setNoResponseShow(true);
    setFirstShow(false);
  };

  const talkToSupport = async () => {
    let obj = {
      senderEmail: "shivam@vodex.ai",
      subject: `Onboarding call didn't work`,
      content: `Hi Team, New user has tried our onboarding call and it didn't worked, here are the details, email ${userDetails?.email} and call uuid is ${call_uuid}  `,
      email: "developers@vodex.ai",
    };
    await dispatch(sendSmsToSupportAction(obj));
    setNoResponseShow(false);
    setSupportShow(true);
  };

  const stars = [1, 2, 3, 4, 5];
  const submitFeedback = async () => {
    if (!starsFeedback) {
      swalWarningMessage("Please select  a rating");
      return;
    }
    let starObj = {
      "star-5": "star-1",
      "star-4": "star-2",
      "star-3": "star-3",
      "star-2": "star-4",
      "star-1": "star-5",
    };
    let obj = {
      call_uuid: call_uuid,
      feedback: {
        stars: starObj[starsFeedback],
        feedbackText: feedbackText,
      },
    };
    let res: any = await dispatch(saveFeedbackAction(obj));
    // if (res) {
    setState((prev) => ({
      ...prev,
      feedBackSaved: true,
    }));
    // }
  };

  return (
    <div>
      <Carousel
        style={{ width: "100%" }}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
        keyboard={false}
      >
        {slides}
      </Carousel>

      <ModalWithoutBody
        show={show}
        toggle={() => setShow(false)}
        body={
          <>
            {firstShow && (
              <div className="chat-feedback">
                <img src={CallPage} />
                <span className="text-align-center mb-0 pb-0">
                  We are connecting your call...
                </span>
                <p className="text-align-center mt-0 pt-0 mb-0 pb-0">
                  You should be getting a call in 2 minutes.
                </p>
                <span className="text-align-center mt-0 pt-0">
                  Let us know if you....
                </span>

                <div>
                  <button className="green-border" onClick={openFeedBack}>
                    Received the call
                  </button>
                  <button className="red-border" onClick={openNoResponseScreen}>
                    Didn't receive the call
                  </button>
                </div>
              </div>
            )}

            {feedbackShow && (
              <div className="chat-feedback">
                <div className="text-wrapper">
                  <p className="text-feedback">Please Rate Your Experience</p>
                  <p className="text-secondary">to help us serve you better</p>
                </div>

                <div className="rating-stars-container">
                  {stars.map((star, index) => (
                    <React.Fragment key={index}>
                      <input
                        value={`star-${star}`}
                        name="star"
                        id={`star-${star}`}
                        type="radio"
                        onChange={(e) => {
                          e.persist();
                          setState((prev) => ({
                            ...prev,
                            starsFeedback: e.target.value,
                          }));
                        }}
                      />
                      <label htmlFor={`star-${star}`} className="star-label">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                            pathLength="360"
                          ></path>
                        </svg>
                      </label>
                    </React.Fragment>
                  ))}
                </div>
                <textarea
                  className="form-control"
                  name="feedbackText"
                  value={feedbackText}
                  placeholder="(optional) Additional feedback..."
                  onChange={onChange}
                />
                <div className="d-flex justify-content-center">
                  {feedBackSaved ? (
                    <CustomNextButton1
                      onClick={continueToDashboard}
                      name="Continue to Dashboard"
                    />
                  ) : (
                    <button className="green-border " onClick={submitFeedback}>
                      {" "}
                      Submit
                    </button>
                  )}
                </div>
              </div>
            )}

            {noResponseShow && (
              <div className="chat-feedback">
                <img src={NoResponse} />

                <p className="text-align-center mb-0 ">
                  Sorry, It didn't work...{" "}
                </p>
                <p className="text-align-center mt-0 mb-0 ">
                  What would you like to do?{" "}
                </p>

                <div>
                  <button
                    className="green-border"
                    onClick={() => {
                      setShow(false);
                      setNoResponseShow(false);
                      setFirstShow(false);
                      setFeedbackShow(false);
                    }}
                  >
                    Try Again
                  </button>
                  <button className="red-border" onClick={talkToSupport}>
                    Talk to our support
                  </button>
                </div>
              </div>
            )}

            {supportShow && (
              <div className="chat-feedback">
                <img src={SupportImg} />

                <p className="text-align-center mb-0 ">
                  We received your mail!{" "}
                </p>
                <p className="text-align-center mt-0 mb-0 ">
                  Our team will get back to you within 2 hours
                </p>

                <div className="d-flex justify-content-center">
                  <CustomNextButton1
                    onClick={continueToDashboard}
                    name="Continue to Dashboard"
                  />
                </div>
              </div>
            )}
          </>
        }
      />
    </div>
  );
}

export default OnboardingPage;
