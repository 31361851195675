import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  checkAccountNameExistAction,
  generateVerificationCodeAction,
  saveAccountNameAction,
  verifyVerificationCodeAction,
} from "../Actions/CommonActions";
import { useHistory, useLocation } from "react-router-dom";
import {
  Countries,
  LoadingDots,
  PhoneInput,
  Select,
  isVodexLoggedin,
} from "../CommonComponents/Common";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import { clearAccountReducerAction, logoutAction } from "../Store/Account/Account.action";
import { clearProjectReducer } from "../Projects/Store/projects.action";
import { clearCallSettingReducerAction } from "../ProjectSection/CallSettings/Store/callSetting.action";
import { clearAudienceReducer } from "../ProjectSection/AudienceSection/Store/Audience.action";

function AssignName() {
  let countries = [
    { value: "d", name: "f" },
    { value: "d", name: "f" },
    { value: "d", name: "f" },
  ];
  const countryCodes = Object.keys(countries);
  const countryNames = countryCodes.map((code) => countries[code]?.name);
  // console.log(countryNames);
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: "",
    phone: "",
    errPhone: false,
    errName: false,
    alreadyExist: false, alreadyExistValid: false,
    count: 0,
    country: "United States",
    verificationNumber: "",
    errCountry: false,
    spin: false,
  });
  const [resend, setResend] = useState(0);
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);
  const [showall, setShowall] = useState(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  const {
    name,
    errName,
    alreadyExist,
    count,
    country,
    errCountry,
    spin,
    phone,
    errPhone,
    verificationNumber,
  } = state;


  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      history.push("/");
    }
  }, []);

  const handleSave = async (e, nav) => {
    e.preventDefault();

    if (country === "") {
      setState((prev) => ({ ...prev, errCountry: true }));
    } else if (name === "") {
      setState((prev) => ({ ...prev, errName: true }));
    } else {
      if (!alreadyExist) {
        setState((prev) => ({ ...prev, spin: true }));
        let accountId = locSearch.get("account-id");
        let currency = "usd";
        if (country === "India") {
          currency = "inr";
        }
        let obj = {
          accountName: name,
          accountId: accountId,
          currency,
        };

        await dispatch(saveAccountNameAction(obj, history));
        if (nav) {
          history.push("/account/dashboard")
        }
        setState((prev) => ({ ...prev, spin: false }));
      }
    }
  };

  useEffect(() => {
    if (count > 0) {
      if (name === "") {
        setState((prev) => ({ ...prev, errName: true }));
      } else {
        setState((prev) => ({ ...prev, errName: false }));
      }
    }
    const delayDebounceFn = setTimeout(async () => {
      if (name !== "") {
        let res: any = await dispatch(checkAccountNameExistAction(name));
        if (!res?.data.available) {
          setState((prev) => ({
            ...prev,
            alreadyExist: true,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            alreadyExist: false,
          }))
        }
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [name]);



  const onChange = async (e) => {
    let val = e.target.value;
    e.persist();
    setState((prev) => ({ ...prev, name: val, count: 1 }));
  };
  const [showButton, setShowButton] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);
    }
    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);
  const generateVerificationCode = async (e) => {
    e.preventDefault();
    setTimeLeft(59);
    setIsRunning(true);
    setCode("");
    setResend(resend + 1);
    if (
      verificationNumber === "" ||
      country === "" ||
      verificationNumber.length <= 8
    ) {
      swalWarningMessage("A phone number and country are required.");
    } else {
      setState((prev) => ({ ...prev, spin: true }));
      let obj = {
        phoneNumber: `+${verificationNumber}`,
        country: country,
      };
      await dispatch(generateVerificationCodeAction(obj));
      setState((prev) => ({ ...prev, spin: false }));
      setShowButton(true);
    }
  };

  const verifyVerification = async () => {
    if (code.length < 4) {
      swalWarningMessage("Please enter valid code");
      return;
    }
    setState((prev) => ({ ...prev, spin: true }));
    let obj = {
      otp: code,
    };
    let res: any = await dispatch(verifyVerificationCodeAction(obj, history));
    if (res === 200) {
      setShowall(true);
      setShow(true);
      setShowButton(false);
    }
    setState((prev) => ({ ...prev, spin: false }));
  };
  let selobj = { name: "United States" };
  return (
    <div className="limiter">
      <div
        className={`container-login100 container-logout  ${!isVodexLoggedin() && "whitelabel-background"
          }`}
      >
        {/* Logout Button */}
        <span className="new-logout" style={isVodexLoggedin() ? { backgroundColor: "#fd7e14", color: "whitesmoke" } : { backgroundColor: "white", color: "#737373" }}
          onClick={async () => {
            await dispatch(clearAccountReducerAction());
            await dispatch(clearProjectReducer());
            await dispatch(clearAccountReducerAction());
            await dispatch(clearCallSettingReducerAction());
            await dispatch(clearAudienceReducer());
            await dispatch(logoutAction(history));
            history.push("/");
          }}
        >
          <i className="fas fa-sign-out-alt fa-sm fa-fw"></i>
        </span>
        {/* ------------- */}

        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <div className="wrap-input100 validate-input m-b-2">
            <span className="label-input100"></span>

            <input
              className="input100 "
              type="text"
              name="email"
              placeholder="Enter Account Name"
              required={true}
              value={name}
              onChange={onChange}
            />
            {errName && (
              <span
                className="alert-validate"
                data-validate={"Account name is required"}
              ></span>
            )}

            {alreadyExist && (
              <span
                className="alert-validate"
                data-validate={"This account name is not available"}
              ></span>
            )}
            <span className="focus-input100" data-symbol="&#xf206;"></span>
            {console.log(isVodexLoggedin())}
          </div>
          {isVodexLoggedin() ? (
            <>
              <div className="wrap-input100 validate-input m-b-2">
                <span className="label-input100"></span>

                <Select
                  maxMenuHeight={160}
                  className="basic-single z-index-high"
                  classNamePrefix="select"
                  value={Countries?.find((name: any) => name.value === country)}
                  isDisabled={false}
                  isSearchable={true}
                  options={Countries}
                  getOptionLabel={(e: any) => e.name}
                  getOptionValue={(e: any) => e.name}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      country: e?.name || "",
                    }));
                  }}
                  placeholder="Select Country"
                  defaultValue={selobj}
                />

                <span className="focus-input100" data-symbol="&#xf206;"></span>
              </div>
              <div className="wrap-input100 validate-input m-b-23">
                <span className="label-input100"></span>

                <PhoneInput
                  specialLabel={""}
                  country={"us"}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      verificationNumber: e,
                    }));
                  }}
                  value={verificationNumber}
                  enableLongNumbers={true}
                />

                <span className="focus-input100"></span>
              </div>

              {showButton && (
                <div className="otp-input-container">
                  <div className={resend <= 2 ? "form-card" : "form-card-otp"}>
                    <p className="form-card-prompt">
                      Enter 4-digit Verification code received on SMS
                    </p>
                    <div className="form-card-input-wrapper">
                      <input
                        value={code}
                        className="form-card-input"
                        placeholder="____"
                        maxLength={4}
                        type="number"
                        onChange={(e) => {
                          e.persist();
                          console.log();
                          if (e.target.value.length <= 4) {
                            setCode(e.target.value);
                          }
                        }}
                      />
                      <div className="form-card-input-bg"></div>
                    </div>

                    {resend <= 2 ? (
                      <p className="call-again mb-0">
                        <span
                          className="underlined"
                          onClick={
                            timeLeft === 0 ? generateVerificationCode : () => { }
                          }
                          style={{
                            cursor: timeLeft === 0 ? "pointer" : "not-allowed",
                          }}
                        >
                          Resend Code
                        </span>{" "}
                        {timeLeft !== 0 && `in 00:${timeLeft} seconds`}
                      </p>
                    ) : (
                      <p>
                        Didn't receive the code ?
                        <span
                          style={{ cursor: "pointer" }}
                          className="underlined"
                          onClick={(e) => {
                            handleSave(e, true)
                          }}
                        >
                          {" "}
                          Click here to continue
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              )}

              {show && (
                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button
                      className="login100-form-btn"
                      type="submit"
                      onClick={(e) => handleSave(e, false)}
                      disabled={spin}
                    >
                      {spin ? <LoadingDots /> : "Save"}
                    </button>
                  </div>
                </div>
              )}
              {!showButton && !showall && (
                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button
                      className="login100-form-btn"
                      type="submit"
                      onClick={generateVerificationCode}
                      disabled={spin}
                    >
                      {spin ? <LoadingDots /> : "Send Verification Code"}
                    </button>
                  </div>
                </div>
              )}
              {showButton && !showall && (
                <div className="container-login100-form-btn">
                  <div
                    className="wrap-login100-form-btn"
                    style={{ marginTop: "-10px" }}
                  >
                    <div className="login100-form-bgbtn"></div>
                    <button
                      className="login100-form-btn"
                      type="submit"
                      onClick={verifyVerification}
                      disabled={spin}
                    >
                      {spin ? <LoadingDots /> : "Verify"}
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="container-login100-form-btn mt-4">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  onClick={(e) => handleSave(e, false)}
                  disabled={spin}
                >
                  {spin ? <LoadingDots /> : "Save"}
                </button>
              </div>
            </div>
          )}
          {isVodexLoggedin() && (
            <>
              {" "}
              <br />
              <span
                className="call-again mt-2 "
                style={{
                  cursor: "auto",
                }}
              >
                Didn't received code? Please contact support at {""}
                <a href="mailto:support@vodex.ai">support@vodex.ai</a>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AssignName;
