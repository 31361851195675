import React, { useEffect, useState } from "react";
import "./Login.css";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { checkValidationAccountAction } from "../Store/Account/Account.action";
import { googleLoginAction, loginAction } from "../Actions/CommonActions";
import { LoadingDots, goToMentionedRoutes } from "../CommonComponents/Common";
import { swalErrorMessage } from "../../../Constant/swalMessage";
import { GoogleLogin } from "react-google-login";
import RoboSvg from "../../../assets/Images/LoginRob.svg";
import VodexWhiteSvg from "../../../assets/Images/VodexLogoWhite.svg";
function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  const [googleButtonDisable, setGooogleButtonDisable] = useState(false);
  const [state, setState] = useState<any>({
    email: "",
    password: "",
  });
  const { email, password } = state;

  useEffect(() => {
    const init = async () => {
      setSpin(true);
      let token = localStorage.getItem("token");
      // console.log("ttoee", token);
      if (token) {
        let res: any = await dispatch(
          checkValidationAccountAction({ token: `Bearer ${token}` })
        );

        if (res?.errMsg === "authorized") {
          const { accounts, lastLogin, _id } = res?.data;
          let find =
            accounts?.find((acc) => acc?._id === lastLogin?.accountId) ||
            accounts[0];
          if (find && !find.accountName) {
            history.push(`/account-name?account-id=${find._id}`);
          } else {
            await goToMentionedRoutes(find, _id, history, dispatch);
          }
        }
      }

      setSpin(false);
    };

    init();
  }, []);
  const signIn = async (e) => {
    e.preventDefault();
    setSpin(true);
    try {
      let obj = {
        email: email.trim(),
        password: password,
      };
      let res: any = await dispatch(loginAction(obj));
      if (res) {
        const { accounts, lastLogin, _id } = res;
        let find =
          accounts?.find((acc) => acc?._id === lastLogin?.accountId) ||
          accounts[0];
        if (find && !find.accountName) {
          history.push(`/account-name?account-id=${find._id}`);
        } else {
          await goToMentionedRoutes(find, _id, history, dispatch);
        }
      }
    } catch (error) {
      swalErrorMessage("Email or Password is incorrect");
    }
    setSpin(false);
  };

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    history.push("/");
  }, []);

  const onFailure = (error) => {
    // alert(error);
    swalErrorMessage(error?.err);
  };

  const googleResponse = async (response) => {
    setGooogleButtonDisable(true);
    if (response.tokenId) {
      let obj = {
        token: response.tokenId,
      };
      let res: any = await dispatch(googleLoginAction(obj));
      if (res) {
        const { accounts, lastLogin, _id } = res;
        let find =
          accounts?.find((acc) => acc?._id === lastLogin?.accountId) ||
          accounts[0];
        if (find && !find.accountName) {
          history.push(`/account-name?account-id=${find._id}`);
        } else {
          await goToMentionedRoutes(find, _id, history, dispatch);
        }
      }
    }
    setGooogleButtonDisable(false);
  };
  return (
    <>
      <div className="d-flex">
        <div className="mobile-view">
          {" "}
          <div className="main-div">
            <img className="vodex-img" src={VodexWhiteSvg} />{" "}
            <div className="container-1">
              <img
                src={RoboSvg}
                alt="Responsive"
                className="responsive-image"
              />
            </div>
            <div className="main-div-2">
              {" "}
              <span className="sales-line">
                Sales Engagement & Conversational Platform <br />{" "}
                <span>
                  Copyright <i className="far fa-copyright"></i> 2022 Vodex{" "}
                </span>{" "}
              </span>{" "}
            </div>{" "}
          </div>
        </div>

        <div className="login-div">
          <div className="outer-div">
            <form onSubmit={signIn}>
              <h5 className="login-heading">Welcome back!</h5>
              <span className="login-sub-heading">
                Please enter your credentials to sign in!
              </span>

              <div
                className="wrap-input100 validate-input mt-3"
                data-validate="Username is reauired"
              >
                <span className="label-input100">Email</span>
                <input
                  className="input100"
                  type="email"
                  name="email"
                  placeholder="Type your email"
                  required={true}
                  value={email}
                  onChange={(e) =>
                    handleChange("email", e.target.value.toLowerCase())
                  }
                />
                <span className="focus-input100" data-symbol="&#xf206;"></span>
              </div>

              <div
                className="wrap-input100 validate-input mt-3"
                data-validate="Password is required"
              >
                <span className="label-input100">Password</span>
                <input
                  className="input100"
                  type="password"
                  name="password"
                  placeholder="Type your password"
                  required={true}
                  value={password}
                  onChange={(e) => handleChange("password", e.target.value)}
                />
                <span className="focus-input100" data-symbol="&#xf190;"></span>
              </div>

              <div className="text-right p-t-8 p-b-31 ">
                <Link to={"/reset-password"} className="forgot-password">
                  Forgot password?
                </Link>
              </div>

              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login-form-btn"
                  type="submit"
                  disabled={spin}
                >
                  {spin ? <LoadingDots /> : "Sign In"}
                </button>
              </div>
            </form>

            <GoogleLogin
              disabled={googleButtonDisable}
              className="google-login"
              clientId={process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID || ""}
              onSuccess={googleResponse}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              icon={true}
            />

            <div className="flex-col-c p-t-38">
              <Link to="/register" className="txt1 p-b-17">
                Don't have an account yet?{" "}
                <span style={{ color: "#ff5e00" }}>Sign up</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
    // <Row>
    //   <Col xs={5} className="mobile-view">
    //     <div className="main-div">
    //       <img className="vodex-img" src={VodexWhiteSvg} />
    //       <div className="main-div-1">
    //         <img className="robo-img" src={RoboSvg} />
    //       </div>
    //       <div className="main-div-2">
    //         {" "}
    //         <span className="sales-line">
    //           Sales Engagement & Conversational Platform
    //           <br />
    //           <span>
    //             Copyright <i className="far fa-copyright"></i> 2022 Vodex
    //           </span>
    //         </span>
    //       </div>
    //     </div>
    //   </Col>

    //   <Col>
    //     <div className="login-div">
    //       <div className="outer-div">
    //         <form onSubmit={signIn}>
    //           <h5 className="login-heading">Welcome back!</h5>
    //           <span className="login-sub-heading">
    //             Please enter your credentials to sign in!
    //           </span>

    //           <div
    //             className="wrap-input100 validate-input mt-3"
    //             data-validate="Username is reauired"
    //           >
    //             <span className="label-input100">Email</span>
    //             <input
    //               className="input100"
    //               type="email"
    //               name="email"
    //               placeholder="Type your email"
    //               required={true}
    //               value={email}
    //               onChange={(e) =>
    //                 handleChange("email", e.target.value.toLowerCase())
    //               }
    //             />
    //             <span className="focus-input100" data-symbol="&#xf206;"></span>
    //           </div>

    //           <div
    //             className="wrap-input100 validate-input"
    //             data-validate="Password is required"
    //           >
    //             <span className="label-input100">Password</span>
    //             <input
    //               className="input100"
    //               type="password"
    //               name="password"
    //               placeholder="Type your password"
    //               required={true}
    //               value={password}
    //               onChange={(e) => handleChange("password", e.target.value)}
    //             />
    //             <span className="focus-input100" data-symbol="&#xf190;"></span>
    //           </div>

    //           <div className="text-right p-t-8 p-b-31 ">
    //             <Link to={"/reset-password"} className="forgot-password">
    //               Forgot password?
    //             </Link>
    //           </div>

    //           <div className="wrap-login100-form-btn">
    //             <div className="login100-form-bgbtn"></div>
    //             <button
    //               className="login-form-btn"
    //               type="submit"
    //               disabled={spin}
    //             >
    //               {spin ? "Signing In" : "Sign In"}
    //             </button>
    //           </div>
    //         </form>

    //         <GoogleLogin
    //           disabled={googleButtonDisable}
    //           className="google-login"
    //           clientId={process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID || ""}
    //           onSuccess={googleResponse}
    //           onFailure={onFailure}
    //           cookiePolicy={"single_host_origin"}
    //           icon={true}
    //         />

    //         <div className="flex-col-c p-t-38">
    //           <Link to="/register" className="txt1 p-b-17">
    //             Don't have an account yet?{" "}
    //             <span style={{ color: "#ff5e00" }}>Sign up</span>
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   </Col>
    // </Row>
  );
}

export default Login;
