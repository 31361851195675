import React from "react";

export function DeleteButton({ bgColor = "", onClick }) {
  return (
    <button
      onClick={onClick}
      className="custom-button-icon button-delete"
      style={{ backgroundColor: bgColor }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 69 14"
        className="svgIcon bin-top"
      >
        <g clip-path="url(#clip0_35_24)">
          <path
            fill="black"
            d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_35_24">
            <rect fill="white" height="14" width="69"></rect>
          </clipPath>
        </defs>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 69 57"
        className="svgIcon bin-bottom"
      >
        <g clip-path="url(#clip0_35_22)">
          <path
            fill="black"
            d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_35_22">
            <rect fill="white" height="57" width="69"></rect>
          </clipPath>
        </defs>
      </svg>
    </button>
  );
}

export function BinButton({ className = "", onClick }) {
  return (
    <button className={`bin-button ${className}`} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 39 7"
        className="bin-top"
      >
        <line strokeWidth="4" stroke="white" y2="5" x2="39" y1="5"></line>
        <line
          strokeWidth="3"
          stroke="white"
          y2="1.5"
          x2="26.0357"
          y1="1.5"
          x1="12"
        ></line>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 33 39"
        className="bin-bottom"
      >
        <mask fill="white" id="path-1-inside-1_8_19">
          <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
        </mask>
        <path
          mask="url(#path-1-inside-1_8_19)"
          fill="white"
          d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
        ></path>
        <path strokeWidth="4" stroke="white" d="M12 6L12 29"></path>
        <path strokeWidth="4" stroke="white" d="M21 6V29"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 89 80"
        className="garbage"
      >
        <path
          fill="white"
          d="M20.5 10.5L37.5 15.5L42.5 11.5L51.5 12.5L68.75 0L72 11.5L79.5 12.5H88.5L87 22L68.75 31.5L75.5066 25L86 26L87 35.5L77.5 48L70.5 49.5L80 50L77.5 71.5L63.5 58.5L53.5 68.5L65.5 70.5L45.5 73L35.5 79.5L28 67L16 63L12 51.5L0 48L16 25L22.5 17L20.5 10.5Z"
        ></path>
      </svg>
    </button>
  );
}

export function ProjectUseButton({
  className = "",
  onClick,
  type = "button",
  title = "Use",
}) {
  let type1: any = type;
  return (
    <button
      className={`use-button ${className}`}
      onClick={onClick}
      type={type1}
    >
      <span>{title}</span>
    </button>
  );
}

export function FavouriteButton({
  className = "",
  onClick,
  type = "button",
  title = "Use",
}) {
  let type1: any = type;
  return (
    <button
      className={`use-button1 ${className}`}
      onClick={onClick}
      type={type1}
    >
      <i className="fas fa-star" />
      <span>{title}</span>
    </button>
  );
}

export function SaveButton({
  className = "",
  onClick = () => {},
  type = "button",
  disabled = false,
  title,
}) {
  let type1: any = type;

  return (
    <button
      className={`button ${className}`}
      type={type1}
      disabled={disabled}
      onClick={onClick}
      title={title}
    >
      <span className="button-decor"></span>
      <div className="button-content">
        <div className="button__icon">
          <svg
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            style={{ marginTop: "3px" }}
          >
            <circle
              opacity="0.5"
              cx="25"
              cy="25"
              r="23"
              fill="url(#icon-payments-cat_svg__paint0_linear_1141_21101)"
            ></circle>
            <defs>
              <linearGradient
                id="icon-payments-cat_svg__paint0_linear_1141_21101"
                x1="25"
                y1="2"
                x2="25"
                y2="48"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" stopOpacity="0.71"></stop>
                <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
          <i
            className="fas fa-save"
            style={{ marginTop: "-35.5px", color: "#fff", zIndex: "1" }}
          ></i>
        </div>
        <span className="button__text">{title}</span>
      </div>
    </button>
  );
}
export function AddButton({ onClick, title, className = "" }) {
  return (
    <button className={`add-button learn-more ${className}`} onClick={onClick}>
      <span className="circle" aria-hidden="true">
        <span className="icon arrow">
          <i className="fas fa-plus text-white ml-2"></i>
        </span>
      </span>
      <span className="button-text">{title}</span>
    </button>
  );
}

export const StarCheckBox = ({ id, onChange, checked }) => {
  return (
    <>
      <input
        onChange={onChange}
        checked={checked}
        type="checkbox"
        id={`${id}`}
        className="hidden-checkbox "
      />
      <label
        htmlFor={`${id}`}
        className="hidden-label mr-1"
        title="Click on this icon to mark this campaign as favourite"
      >
        <svg viewBox="0 0 24 24">
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
        </svg>
      </label>
    </>
  );
};

export const BounceLoader = () => (
  <div className="wrapper1">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
  </div>
);
