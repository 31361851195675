import React from "react";
import { Table } from "react-bootstrap";

function CommonTable({ body, headers }) {
  return (
    <Table className="table-centered table-nowrap rounded mb-0" hover>
      <thead className="thead-light">
        <tr>
          {headers.map((head) => {
            if (head.includes(":"))
              return (
                <th className={`border-0 text-center`}>{head.split(`:`)[0]}</th>
              );
            else return <th className="border-0">{head}</th>;
          })}
        </tr>
      </thead>
      <tbody>{body}</tbody>
    </Table>
  );
}

export default CommonTable;
